import React from "react";
import tag from "../assets/images/tag.png";
import shopping from "../assets/images/shopping.png";
import group from "../assets/images/group.png";
import cart from "../assets/images/cart.png";
import edit from "../assets/images/icons/edit.png";
import Table from "../Components/Table";
import TableContent from "../Components/TableContent";
import TableHeader from "../Components/TableHeader";
import { ViewChart } from "../Components/ViewChart";

const Home = () => {
  return (
    <>
      <div className="row">
        <h1 className="h2">Accueil</h1>
      </div>

      
    </>
  );
};

export default Home;
