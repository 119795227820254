
const endPoint = {
    login:"security/login",
    categories:"categories",
    produits:"produits",
    promotions:"promotions",
    livreurs:"livreurs",
    fournisseurs:"fournisseurs",
    clients:"clients",
    administrateurs:"administrateurs",
}

export default endPoint